<template>
  <!--Model宽度：850px-->
  <a-spin :spinning="pageLoading">
    <div style="position: relative; margin-bottom: 8px;">
      <div style="padding-left: 8px;" class="modal-text-title">当前数据</div>
      <i style="position: absolute; top: 5px; left: 0; width: 3px; height: 17px; background: rgba(0, 0, 0, 0.65);"></i>
    </div>
    <a-table
      :columns="currentDataColumns"
      :dataSource="currentData"
      :rowKey="(record, index) => index"
      :pagination="false"
      :scroll="{ y: 240 }"
      style="margin-bottom: 32px;"
      size="small"
      bordered
      :rowClassName="(record, index) => {   let className = 'light-row'
      if (record.warn) className = 'dark-row'
      return className}"
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ index + 1 }}
      </template>
      <template slot="collectValue" slot-scope="text, record">
        <span>{{ record.collectValue | roundNumber }} {{ record.unit.length > 0 ? '(' + record.unit + ')' : ''}}</span>
      </template>
      <template slot="fault" slot-scope="text, record">
        <a-tag color="#e91010" v-if="record.fault">是</a-tag>
        <a-tag color="#108ee9" v-else>否</a-tag>
      </template>
      <template slot="warn" slot-scope="text, record">
        <a-tag color="#e91010" v-if="record.warn">是</a-tag>
        <a-tag color="#108ee9" v-else>否</a-tag>
      </template>
      <template slot="collectTime" slot-scope="text, record">
        {{moment(record.collectTime).format('YYYY-MM-DD HH:mm:ss')}}
      </template>

      <template slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleMetricsHistory(record)">历史数据</a>
      </template>
    </a-table>
    <div v-show="metricsHistoryShow">
      <div style="position: relative;">
        <div style="padding-left: 8px;" class="modal-text-title">{{ tempRealName }} - 历史数据</div>
        <i
          style="position: absolute; top: 5px; left: 0; width: 3px; height: 17px; background: rgba(0, 0, 0, 0.65);"
        ></i>
      </div>
      <a-spin :spinning="metricsHistoryLoading">
        <a-tabs defaultActiveKey="chart">
          <div slot="tabBarExtraContent">
            <a-button-group style="margin-right: 5px;">
              <a-button @click="handleChangeMetricsHistoryDate('day')">今日</a-button>
              <a-button @click="handleChangeMetricsHistoryDate('week')">本周</a-button>
              <a-button @click="handleChangeMetricsHistoryDate('month')">本月</a-button>
              <!--<a-button @click="handleChangeMetricsHistoryDate('year')">全年</a-button>-->
            </a-button-group>
            <a-range-picker
              :disabledDate="disabledDate"
              :value="metricsHistoryTime"
              :showTime="{ format: 'HH:mm' }"
              :allowClear="false"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD HH:mm"
              @ok="handleModalPickerOk"
              @change="(value) => (metricsHistoryTime = value)"
            />
          </div>
          <a-tab-pane tab="图表" key="chart">
            <area01
              :data="chartMetricsHistoryData"
              yText="指标值"
              xName="collectTime"
              yName="collectValue"
              :height="400"
            ></area01>
          </a-tab-pane>
          <a-tab-pane tab="数据" key="data">
            <a-table
              :columns="metricsHistoryTableColumns"
              :dataSource="metricsHistoryData"
              :rowKey="(record, index) => index"
              :pagination="pagination"
              @change="tableChange"
              :scroll="{ y: 396 }"
              size="small"
              bordered
            >
              <template slot="_index" slot-scope="text, record, index">
                {{ index + 1 }}
              </template>
              <template slot="collectValue" slot-scope="text, record">
                <span>{{ record.collectValue | roundNumber }}</span>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
  </a-spin>
</template>

<script>
  import moment from 'moment'
  import Area01 from '@/components/fs-chart/Area01'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'Detail',
    components: { Area01 },
    data () {
      return {
        // ↓页面的加载
        pageLoading: false,
        // ↓当前数据表头
        currentDataColumns: [
          {
            title: '序号',
            dataIndex: '_index',
            align: 'center',
            width: 50,
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '指标名称',
            width: 200,
            align: 'center',
            dataIndex: 'cnName'
          },
          {
            title: '指标值',
            width: 120,
            align: 'center',
            scopedSlots: { customRender: 'collectValue' }
          },
          {
            title: '故障',
            width: 50,
            align: 'center',
            scopedSlots: { customRender: 'fault' }
          },
          // {
          //   title: '预警',
          //   width: 50,
          //   align: 'center',
          //   scopedSlots: { customRender: 'warn' }
          // },
          {
            title: '采集时间',
            width: 200,
            align: 'center',
            scopedSlots: { customRender: 'collectTime' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            align: 'center',
            width: 100,
            scopedSlots: { customRender: 'action' }
          }
        ],
        // ↓当前数据
        currentData: [],
        // ↓历史数据的显示
        metricsHistoryShow: false,
        // ↓当前监测点Id
        tempPointId: null,
        // ↓当前实时指标Id
        tempRealId: null,
        // ↓当前指标名称
        tempRealName: '暂未选择指标',
        // ↓Modal中历史数据的加载状态
        metricsHistoryLoading: false,
        // 历史数据的时间值
        metricsHistoryTime: [moment().startOf('day'), moment().endOf('day')],
        metricsHistoryStartTime: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        metricsHistoryEndTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // 历史数据的表头
        metricsHistoryTableColumns: [
          {
            title: '序号',
            dataIndex: '_index',
            align: 'center',
            width: 80,
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '采集时间',
            width: 450,
            align: 'center',
            dataIndex: 'collectTime'
          },
          {
            title: '指标值',
            align: 'center',
            // dataIndex: 'collectValue',
            scopedSlots: { customRender: 'collectValue' }
          }
        ],
        // 历史数据的Data
        metricsHistoryData: [],
        chartMetricsHistoryData: [],
        searchMetricsHistoryDataParams: {
          page: 0,
          size: 10,
          pointId: '',
          realId: '',
          startTime: '',
          endTime: ''
        },
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        }
      }
    },
    methods: {
      moment,
      loadData (id) {
        this.currentData = []
        this.tempPointId = id
        if (!this.pageLoading) {
          this.pageLoading = true
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringRealInfo.findList,
            noTips: true,
            data: { pointId: id },
            success: (data) => {
              this.pageLoading = false
              this.currentData = data.body
            },
            error: () => {
              this.pageLoading = false
            }
          })
        }
      },
      // ↓查看历史数据
      handleMetricsHistory (item) {
        this.restPage()
        this.metricsHistoryShow = true
        this.metricsHistoryData = []
        if (item != null) {
          this.tempRealId = item.id || null
          this.tempRealName = item.cnName || '暂未选择指标'
        }
        if (!this.tempPointId) {
          this.$message.warning('请刷新之后重试!')
          return
        }
        if (!this.tempRealId) {
          this.$message.warning('请确定点击指标历史数据后重试!')
          return
        }
        if (!this.metricsHistoryStartTime && !this.metricsHistoryEndTime) {
          this.$message.warning('请选择时间并确认之后重试!')
          return
        }
        if (!this.metricsHistoryLoading) {
          this.searchMetricsHistoryDataParams.pointId = this.tempPointId
          this.searchMetricsHistoryDataParams.realId = this.tempRealId
          this.searchMetricsHistoryDataParams.startTime = this.metricsHistoryStartTime
          this.searchMetricsHistoryDataParams.endTime = this.metricsHistoryEndTime
          this.metricsHistoryLoading = true
          // this.requestData()
          this.getChartMetricsHistoryList()
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringRealInfo.metricsHistoryPage,
          noTips: true,
          data: this.searchMetricsHistoryDataParams,
          success: (data) => {
            this.metricsHistoryData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchMetricsHistoryDataParams.page + 1
            this.metricsHistoryLoading = false
            // this.getChartMetricsHistoryList()
          },
          error: () => {
            this.metricsHistoryLoading = false
          }
        })
      },
      getChartMetricsHistoryList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringRealInfo.metricsHistoryList,
          noTips: true,
          data: this.searchMetricsHistoryDataParams,
          success: (data) => {
            this.chartMetricsHistoryData = data.body
          },
          error: () => {
            this.metricsHistoryLoading = false
          }
        })
      },
      handleMetricsHistoryByPage () {
        if (!this.metricsHistoryLoading) {
          this.requestData()
        }
      },
      requestData () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringRealInfo.metricsHistoryPage,
          noTips: true,
          data: this.searchMetricsHistoryDataParams,
          success: (data) => {
            this.metricsHistoryData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchMetricsHistoryDataParams.page + 1
            this.pagination.pageSize = data.body.size
            this.metricsHistoryLoading = false
          },
          error: () => {
            this.metricsHistoryLoading = false
          }
        })
      },
      disabledDate (current) {
        return current < moment().subtract(6, 'months')
      },
      handleChangeMetricsHistoryDate (type) {
        this.metricsHistoryTime = [moment().startOf(type), moment().endOf(type)]
        this.metricsHistoryStartTime = this.metricsHistoryTime[0].format('YYYY-MM-DD HH:mm:ss')
        this.metricsHistoryEndTime = this.metricsHistoryTime[1].format('YYYY-MM-DD HH:mm:ss')
        this.handleMetricsHistory()
      },
      handleModalPickerOk (value) {
        this.metricsHistoryStartTime = value[0].format('YYYY-MM-DD HH:mm:ss')
        this.metricsHistoryEndTime = value[1].format('YYYY-MM-DD HH:mm:ss')
        this.handleMetricsHistory()
      },
      destructionModal () {
        this.metricsHistoryData = []
        this.tempPointId = null
        this.tempRealId = null
        this.tempRealName = '暂未选择指标'
        this.metricsHistoryShow = false
      },
      tableChange (pagination, filters, sorter) {
        this.searchMetricsHistoryDataParams.page = pagination.current - 1
        this.searchMetricsHistoryDataParams.size = pagination.pageSize
        this.handleMetricsHistoryByPage()
      },
      restPage () {
        this.searchMetricsHistoryDataParams.page = 0
      }
    },
    filters: {
      roundNumber (value) {
        if (typeof value === 'number') {
          let result = null
          // ↓四舍五入的值
          result = Math.round(value * 100) / 100
          // 判断四舍五入的值是不是小数
          if (result - parseInt(result) > 0) {
            // 是小数,补零算法
            let s_x = result.toString()
            let pos_decimal = s_x.indexOf('.')
            if (pos_decimal < 0) {
              pos_decimal = s_x.length
              s_x += '.'
            }
            while (s_x.length <= pos_decimal + 2) {
              s_x += '0'
            }
            return s_x
          } else {
            // 是整数，直接返回
            return result
          }
        } else {
          return value
        }
      }
    }
  }
</script>

<style scoped>
  .modal-text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }

</style>
<style>
  .light-row {
    background-color: #fff;
  }

  .dark-row {
    background-color: #fde1e1;
  }
</style>
