<template>
  <div>
    <a-button @click="clickMonitoring">设备实时监测</a-button>
    <a-modal
      v-model="modal"
      title="设备实时监测"
      :centered="true"
      :width="bodyWidth">
      <MonitoringTable ref="monitoringRef"></MonitoringTable>
      <a-button slot="footer" @click="handleMoreMetricsModalCancel">取消</a-button>
    </a-modal>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import MonitoringTable from '../../../../equipment2/monitoring/Table'

  export default {
    components: { MonitoringTable },
    data () {
      return {
        modal: false
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth - 140
          return width > 1400 ? 1400 : width
        }
      })
    },
    methods: {
      clickMonitoring () {
        this.modal = true
        this.$nextTick(() => {
          this.$refs.monitoringRef.visible = false
          this.$refs.monitoringRef.display = 'card'
          this.$refs.monitoringRef.cardMarginTop = '0px'
        })
      },
      handleMoreMetricsModalCancel () {
        this.modal = false
      }
    }
  }
</script>