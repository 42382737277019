<template>
  <a-tree-select
    style="width: 160px;margin-left: 10px"
    allowClear
    :treeData="userList"
    v-model="userStr"
    @change="onChange"
    placeholder="请选择人"
  >
  </a-tree-select>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    data () {
      return {
        userList: [],
        userStr: undefined,
        userId: undefined
      }
    },
    created () {
      this.loadUser()
    },
    methods: {
      loadUser () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      onChange (value, label, extra) {
        this.userId = this.userStr ? this.userStr.split('_')[2] : undefined
        this.$emit('onChange', this.userId, label[0])
      }
    }
  }
</script>