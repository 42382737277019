<template>
  <div class="page-header-index-wide xunjian-history-list">
    <div class="container-main">
      <div class="container-left">
        <div class="m-r-24">
          <a-form layout="horizontal">
            <a-form-item label="开始时间"
                         :label-col="labelCol"
                         :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%"
                             v-model="startTime"
                             format="YYYY-MM-DD"
                             @change="startDateChange"
                             placeholder="请选择开始时间"></a-date-picker>
            </a-form-item>
            <a-form-item label="结束时间"
                         :label-col="labelCol"
                         :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%"
                             v-model="endTime"
                             format="YYYY-MM-DD"
                             @change="endDateChange"
                             placeholder="请选择结束时间"></a-date-picker>
            </a-form-item>
            <a-form-item style="text-align: center;">
              <a-button type="primary" @click="onSearch" block>查询</a-button>
            </a-form-item>
          </a-form>
          <a-divider style="margin:10px 0 6px 0"></a-divider>
        </div>
        <div>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.totalTimes||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检次数
              </div>
            </a-col>
            <a-col :span="10" :offset="2">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.allDuration||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检时长
              </div>
            </a-col>
          </a-row>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.allMileage||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检公里
              </div>
            </a-col>
            <a-col :span="10" :offset="2">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.recordNodeTimes||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检设备次数
              </div>
            </a-col>
          </a-row>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.normalTimes||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检正常次数
              </div>
            </a-col>
            <a-col :span="10" :offset="2">
              <div style="font-size: 22px;text-align: center;color: #656565;">
                {{inspectionStatistics.abnormalTimes||'-'}}
              </div>
              <div style="font-size: 14px;text-align: center;color: #656565;">
                巡检异常次数
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="container-right p-l-24">
        <a-table size="middle"
                 :columns="columns"
                 :dataSource="tableData"
                 :loading="tableLoading"
                 :pagination="pagination"
                 @change="tableChange"
                 :customRow="customRow"
                 :scroll="{x:600}"
                 :rowKey="record => record.id"
                 bordered>
          <template slot="_index" slot-scope="text, record, index">
            {{searchParams.number*searchParams.size+index+1}}
          </template>
          <template slot="result" slot-scope="text, record">
            <a-tag class="m-r-none" color="blue" v-if="record.result==='NORMAL'">正常</a-tag>
            <a-tag class="m-r-none" color="red" v-if="record.result==='ABNORMAL'">异常</a-tag>
          </template>
          <template slot="finish" slot-scope="text, record">
            <a-tag class="m-r-none" color="red" v-if="record.finish==='UNFINISHED'">未完成</a-tag>
            <a-tag class="m-r-none" color="blue" v-if="record.finish==='FINISHED'">已完成</a-tag>
          </template>
          <template slot="number" slot-scope="text">
            <span>{{text === 0 ? '无' : text}}</span>
          </template>
          <template slot="abnormalAmount" slot-scope="text, record">
            <span :class="[record.abnormalAmount>0?'text-color-danger':'']">{{record.abnormalAmount === 0 ? '无' : record.abnormalAmount}}</span>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal title="巡检详情" :visible="infoModal" :footer="null" :width="1400"
             centered @cancel="closeModal">
      <xunjian-passed ref="passedRef"
                      :mapHeight="350"
                      :dbDevicesData="dbDevicesData"></xunjian-passed>
    </a-modal>
  </div>
</template>

<script>

  import entityCRUD from '../../common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import { myInspectionColumns } from '../../xj/myInspection/common/common'
  import XunjianPassed from './XunjianPassed'

  export default {
    name: 'HistoryList',
    props: {
      // 需要显示在地图中的自定义标注物
      dbDevicesData: {
        type: Array,
        default: () => {
          return []
        }
      },
      userId: {
        type: Number
      }
    },
    components: { XunjianPassed },
    mixins: [entityCRUD],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.xj.xjRecord,
        initColumns: myInspectionColumns(),
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
        carList: [],
        startTime: undefined,
        endTime: undefined,
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          startTime: undefined,
          endTime: undefined,
          userId: this.userId
        },
        inspectionStatistics: {},
        infoModal: false
      }
    },
    watch: {
      userId (val) {
        this.searchParams.userId = val
      }
    },
    methods: {
      restSearchParams () {
        this.startTime = undefined
        this.endTime = undefined
        this.searchParams.startTime = undefined
        this.searchParams.endTime = undefined
      },
      restAndOnSearch () {
        this.inspectionStatistics = []
        this.restSearchParams()
        this.onSearch()
      },
      onSearch () {
        this.restPage()
        this.search()
        this.getCountTimes()
      },
      getCountTimes () {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecord.count_times,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.inspectionStatistics = data.body
          }
        })
      },
      customRow (record, index) {
        return {
          on: {
            click: () => {
              this.infoModal = true
              this.$nextTick(() => {
                this.$refs.passedRef.loadData(record.id)
              })
            }
          }
        }
      },
      closeModal () {
        this.infoModal = false
      },
      startDateChange (date, dateString) {
        this.searchParams.startTime = dateString
      },
      endDateChange (date, dateString) {
        this.searchParams.endTime = dateString
      }
    }
  }
</script>

<style lang="less">
  .xunjian-history-list {

    .container-main {
      width: 100%;
      display: flex;
      height: 100%;

      .container-left {
        border-right: 1px solid #e8e8e8;
        min-width: 324px;
      }

      .container-right {
        flex: 1 1;
        overflow-x: auto;
      }
    }
  }

</style>