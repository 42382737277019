<template>
    <div class="metrics-box center">
      <span class="font-size-15">{{value}} {{unit}}</span>
      <div class="metrics-box-child">
        <slot name="title">
          <span>{{name}}</span>
        </slot>
      </div>
    </div>
</template>
<script>
  export default {
    name: 'Metrics',
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      unit: {
        type: String,
        default: ''
      },
      name: {
        type: [String, Number],
        default: ''
      }
    },
    watch: {
      value (val) {
          this.value = val
        },
      unit (val) {
        this.unit = val
      },
      name (val) {
        this.name = val
      }
    },
    data() {
      return {}
    },
    methods: {
    }
  }
</script>