// 车辆任务历史列表表头
export const historyColumns = () => {
  return [
    {
      title: '#',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '制定人',
      width: 120,
      align: 'center',
      dataIndex: 'managerUser.name'
    },
    {
      title: '任务执行时间',
      width: 360,
      align: 'center',
      scopedSlots: { customRender: 'executeTime' }
    },
    {
      title: '执行人',
      width: 100,
      align: 'center',
      dataIndex: 'executeUser.name'
    },
    {
      title: '执行内容',
      width: 200,
      align: 'left',
      dataIndex: 'summary',
      ellipsis: true,
    },
    {
      title: '耗时',
      width: 100,
      align: 'center',
      dataIndex: 'durationStr'
    },
    {
      title: '行车公里',
      width: 100,
      align: 'center',
      dataIndex: 'mileage'
    },
    {
      title: '任务状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '任务类型',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '任务制定时间',
      width: 200,
      align: 'center',
      dataIndex: 'dateCreated'
    },
  ]
}
