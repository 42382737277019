<template>
  <div>
    <div style="padding: 10px" v-if="leftDropChecked === 'car'">
      <a-tag color="#108ee9">车辆总数：{{countCarsData.totalNumber || 0}}</a-tag>
      <a-tag color="#108ee9">执行中数量：{{countCarsData.goingNumber || 0}}</a-tag>
      <a-tag color="#6d6969a6">空闲数量：{{countCarsData.freeNumber || 0}}</a-tag>
      <a-tag color="#f5222d">离线数量：{{countCarsData.offlineNumber || 0}}</a-tag>
      <a-tag color="#2db7f5">静止数量：{{countCarsData.staticNumber || 0}}</a-tag>
      <a-tag color="#52c41a">行驶中数量：{{countCarsData.dynamicNumber || 0}}</a-tag>
    </div>
<!--    <div style="background: #ECECEC;padding: 10px;width: 780px" v-if="leftDropChecked === 'xunjian'">-->
    <div style="width: 780px" v-if="leftDropChecked === 'xunjian'">
      <a-row>
        <a-col :span="22" style="text-align: center">
          <a-tag color="#108ee9">巡检总次数：{{countXunjiansData.recordTotalTimes || 0}}</a-tag>
          <a-tag color="#f5222d">巡检异常次数：{{countXunjiansData.recordAbnormalTimes || 0}}</a-tag>
          <a-tag color="#108ee9">巡检点次数：{{countXunjiansData.recordNodeTimes || 0}}</a-tag>
          <a-tag color="#f5222d">巡检点异常次数：{{countXunjiansData.recordNodeAbnormalTimes || 0}}</a-tag>
          <a-row v-if="expand" style="margin-top: 10px;line-height: 30px">
            <a-tag color="#108ee9" v-if="countXunjiansData.allDuration">巡检总时长：{{(countXunjiansData.allDuration).toFixed(2) || 0}}(h)</a-tag>
            <a-tag color="#108ee9" v-if="countXunjiansData.allMileage">巡检总公里数：{{(countXunjiansData.allMileage).toFixed(2) || 0}}(km)</a-tag>
            <a-tag color="#108ee9">巡检总人数：{{countXunjiansData.inspectUserNumbers || 0}}</a-tag>
            <a-tag color="#108ee9">故障派单次数：{{countXunjiansData.ticketAllocatedTimes || 0}}</a-tag>
            <a-tag color="#52c41a">已处理故障次数：{{countXunjiansData.ticketProcessedTimes || 0}}</a-tag>
          </a-row>
        </a-col>
<!--        <a-col :span="2">-->
<!--          <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">-->
<!--            {{ expand ? '收起' : '展开' }}-->
<!--            <a-icon :type="expand ? 'up' : 'down'"/>-->
<!--          </a>-->
<!--        </a-col>-->
      </a-row>
    </div>
    <div style="padding: 10px" v-if="leftDropChecked === 'device'">
      <a-tag color="#108ee9">设备总数量：{{countDeviceData.totalNumber || 0}}</a-tag>
      <a-tag color="#108ee9">在线设备数量：{{countDeviceData.onlineNumber || 0}}</a-tag>
      <a-tag color="#108ee9">库存设备数量：{{countDeviceData.stockNumber || 0}}</a-tag>
      <a-tag color="#52c41a">正常设备：{{countDeviceData.normalNumber || 0}}</a-tag>
      <a-tag color="#f5222d">损坏设备：{{countDeviceData.damageNumber || 0}}</a-tag>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'top',
    props: {
      leftDropChecked: {},
      countCarsData: {},
      countXunjiansData: {},
      countDeviceData: {}
    },
    data () {
      return {
        expand: true,
      }
    },
    methods: {
      toggle() {
        this.expand = !this.expand
      },
    }
  }
</script>