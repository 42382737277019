<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding: 20px;">
      <a-select style="width: 100%;" allowClear @change="deviceTypeChange" placeholder="请选择类型">
        <a-select-option v-for="item in deviceTypeList" :key="item.value" :value="item.value" :label="item.label">
          {{ item.label }}
        </a-select-option>
      </a-select>
      <a-list :bordered="false" :dataSource="searchData">
        <a-list-item
          style="cursor: pointer"
          class=" default-hover"
          slot="renderItem"
          slot-scope="item"
          @click.native="itemClick(item)"
        >
          <a-col :span="3">
            <a-icon type="dashboard" v-if="item.label === 'DEVICE'"></a-icon>
            <a-icon type="block" v-else-if="item.label === 'FACILITY'"></a-icon>
            <a-icon type="home" v-else></a-icon>
          </a-col>
          <a-col :span="14" :title="item.pointName">
            {{item.pointName.length > 12?item.pointName.substring(0,12)+'...':item.pointName}}
          </a-col>
          <a-col :span="7" :title="item.typeName">
            {{item.typeName.length > 5?item.typeName.substring(0,5)+'...':item.typeName}}
          </a-col>
<!--          <a-col :span="4" style="text-align: right;">-->
<!--            <a-tag color="blue" v-if="item.mark">标注</a-tag>-->
<!--            <a-tag color="red" v-else>未标注</a-tag>-->
<!--          </a-col>-->
        </a-list-item>
        <div slot="footer">
          <a-pagination
            size="small"
            :current="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="pagination.showTotal"
            @change="onPageChange"
          />
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import searchListCommon from '../mixins/search-list-common'

  export default {
    name: 'SearchDeviceList',
    mixins: [searchListCommon],
    props: {
      searchKey: {
        type: String,
        default: 'device'
      },
      mapId: {
        type: String,
        default: undefined
      }
    },
    data () {
      return {
        spinShow: false,
        searchParams: {
          page: 0,
          size: 10,
          // 设备，仓库，设施的名称
          name: null,
          mapInfoId: this.mapId,
          // 类型标签
          typeLabel: null
        },
        searchData: [],
        deviceTypeList: [],
        listType: 'device'
      }
    },
    created () {
      this.onSearch()
      this.loadTypeList()
    },
    methods: {
      /**
       * searchKey发生变化 watch会执行当前方法
       * @param value 变化值
       */
      searchKeyChange (value) {
        this.searchParams.name = value
      },
      /**
       * 查询
       */
      loadData () {
        if (this.spinShow) {
          return
        }
        this.$emit('onSearchStart')
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitorPointApi.search,
          noTips: true,
          loading: 'spinShow',
          data: this.searchParams,
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.page + 1
            this.$emit('onSearchSuccess')
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('设备列表检索失败！')
          }
        })
      },
      /**
       * 加载监测点类型列表
       */
      loadTypeList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitorPointApi.searchTypeList2,
          params: {
            mapInfoId: this.mapId
          },
          noTips: true,
          success: (data) => {
            this.deviceTypeList = data.body
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('设备列表类型加载失败！')
          }
        })
      },
      /**
       * 设备类型改变
       */
      deviceTypeChange (value) {
        this.searchParams.typeLabel = value
        this.onSearch()
      },
      itemClick (item) {
        this.$emit('onItemClick', this.listType, item)
      }
    }
  }
</script>
