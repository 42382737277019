<template>
  <div>
    <a-button @click="clickStatus">状态统计面板</a-button>
    <a-button @click="clickByOver">执行任务统计</a-button>
    <a-modal v-model="statusModal" :centered="true" title="状态统计面板" :footer="null" :width="1000">
      <a-row>
        <a-col :span="12" style="padding: 5px ">
          <div class="details" style="margin-top: 0px;">
            <div class="details-title" style="margin-top: 0;">
              <div class="details-title-text">任务执行状态</div>
              <i class="details-title-text-i"></i>
            </div>
          </div>
          <PieChart2 :data="countCarsByStatusData" id="carByStatus"></PieChart2>
        </a-col>
        <a-col :span="12" style="padding: 5px ;">
          <div class="details" style="margin-top: 0px;">
            <div class="details-title" style="margin-top: 0;">
              <div class="details-title-text">GPS设备状态</div>
              <i class="details-title-text-i"></i>
            </div>
          </div>
          <PieChart2 :data="countCarsByOfflineData" id="carByOffline"></PieChart2>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal v-model="byOverTimeModal" :centered="true"  :footer="null" :width="bodyWidth">
      <a-card
        :headStyle="{paddingLeft:0}"
        :bordered="false"
        style="width:100%"
        :tab-list="tabListNoTitle"
        :active-tab-key="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <div v-if="noTitleKey === 'time'">
          <CarTimes :mapId="mapId"></CarTimes>
        </div>
        <div v-else-if="noTitleKey === 'kmAndTime'">
          <CarKmAndTime :mapId="mapId"></CarKmAndTime>
        </div>
        <div v-else>
          <CarKmAndTimeList :mapId="mapId"></CarKmAndTimeList>
        </div>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
  import PieChart2 from '../../statisticsChart/PieChartCar'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import { mapState } from 'vuex'
  import CarKmAndTime from './CarKmAndTime'
  import CarTimes from './CarTimes'
  import CarKmAndTimeList from './CarKmAndTimeList'

  export default {
    name: 'buttonStatistics',
    mixins: [],
    components: { CarKmAndTime, CarTimes, PieChart2, CarKmAndTimeList },
    props: {
      mapId: {}
    },
    data () {
      return {
        noTitleKey: 'time',
        tabListNoTitle: [
          {
            key: 'time',
            tab: '拉运次数统计'
          },
          {
            key: 'kmAndTime',
            tab: '拉运公里时长统计'
          },
          {
            key: 'data',
            tab: '数据'
          }
        ],
        entityBaseUrl: SERVICE_URLS.car.carRecordApi,
        zxVisible: false,
        statisticsSearchParams: {
          // departmentId: this.currentDeptId().organizationId,
          mapId: this.mapId,
          endTimeStr: '',
          startTimeStr: '',
          type: 'date'
        },
        overTimeSearchParams: {},
        countCarsByOfflineData: [],
        countCarsByStatusData: [],
        countByOverTimeData: [],
        time: [],
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          managerUser: undefined,
          executeUser: undefined,
          carInfo: undefined,
          status: 'DOING',
          type: '',
          startTime: '',
          endTime: ''
        },
        initColumns: [
          {
            title: '序号',
            width: 70,
            scopedSlots: { customRender: '_index' },
            align: 'center'
          },
          {
            title: '执行人',
            align: 'center',
            width: 150,
            dataIndex: 'executeUser.name'
          },
          {
            title: '所属部门',
            align: 'center',
            width: 200,
            dataIndex: 'department.name'
          },
          {
            title: '执行车辆',
            width: 200,
            align: 'center',
            dataIndex: 'carInfo.fullName'
          },
          {
            title: '执行内容',
            align: 'center',
            dataIndex: 'summary',
            customRender: (text, record) => {
              const time = `${record.summary ? record.summary : '-'}` //es6写法
              return time
            }
          }
        ],
        statisticByUserData: [],
        time2: [],
        statisticByUserSearch: {
          startTimeStr: '',
          endTimeStr: ''
        },
        statusModal: false,
        byOverTimeModal: false,
        spinning: true,
        byUserModal: false,
        mode: ['month', 'month']
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth - 140
          return width > 1400 ? 1400 : width
        }
      })
    },
    watch: {
      mapId (val) {
        this.mapId = val
      }
    },
    created () {
      this.getStatisticsInfo()
      this.getCountByOverTimeData()
      this.getStatisticByUserData()
      this.getTime('year')
      this.getTime2('month')
    },
    methods: {
      onTabChange (key, type) {
        this[type] = key
      },
      moment,
      clickModal () {
        this.visible = true
      },
      handleCancel () {
        this.visible = false
      },
      getStatisticsInfo () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.count_cars_by_offline,
          noTips: true,
          data: { mapId: this.mapId },
          success: (data) => {
            this.countCarsByOfflineData = data.body
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.count_cars_by_status,
          noTips: true,
          data: { mapId: this.mapId },
          success: (data) => {
            this.countCarsByStatusData = data.body
          }
        })
      },
      getCountByOverTimeData () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.count_by_over_time,
          noTips: true,
          data: this.statisticsSearchParams,
          success: (data) => {
            this.countByOverTimeData = data.body
            this.spinning = false
          }
        })
      },
      rangePickerChange (date, dateString) {
        this.statisticsSearchParams.startTimeStr = dateString[0]
        this.statisticsSearchParams.endTimeStr = dateString[1]
        this.getCountByOverTimeData()
      },
      getStatisticByUserData () {
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.statistic_by_user,
          noTips: true,
          data: this.statisticByUserSearch,
          success: (data) => {
            this.statisticByUserData = data.body
          }
        })
      },
      groupPickerChange (date, dateString) {
        this.statisticByUserSearch.startTimeStr = dateString[0]
        this.statisticByUserSearch.endTimeStr = dateString[1]
        this.getStatisticByUserData()
      },
      clickStatus () {
        this.statusModal = true
      },
      clickByOver () {
        this.byOverTimeModal = true
      },
      tabChange (value) {
        this.statisticsSearchParams.type = value
        this.getCountByOverTimeData()
      },
      changeRadioGroup (value) {
        this.getTime(value.target.value)
        this.getCountByOverTimeData()
      },
      getTime (val) {
        this.time = [moment().startOf(val), moment().endOf(val)]
        this.statisticsSearchParams.startTimeStr = this.time[0].format('YYYY-MM-DD')
        this.statisticsSearchParams.endTimeStr = this.time[1].format('YYYY-MM-DD')
      },
      clickByUser () {
        this.byUserModal = true
      },
      getTime2 (val) {
        this.time2 = [moment().startOf(val), moment().endOf(val)]
        this.statisticByUserSearch.startTimeStr = this.time2[0].format('YYYY-MM-DD')
        this.statisticByUserSearch.endTimeStr = this.time2[1].format('YYYY-MM-DD')
      }
    }
  }
</script>
<style  scoped>
  button {
    margin-left: 10px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.349019607843137);
    color: black;
  }
</style>

