<template>
  <right-form-container :title="formItem.name" v-model="visible" @close="close">
    <a-spin :spinning="spinShow" :delay="200">
      <div
        v-malihu-scrollbar
        class="mCustomScrollbar"
        style="overflow-y: auto; position: relative; top: 0; left: 0;"
        :style="{ 'max-height': bodyHeight + 'px' }"
      >
        <div class="p-12">
          <a-form layout="inline">
            <a-row>
              <a-form-item label="所属部门" class="form-line-height">
                <span>{{ formItem.deptName }}</span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item label="所在位置" class="form-line-height">
                <span>{{ formItem.location }}</span>
              </a-form-item>
            </a-row>
          </a-form>
        </div>
        <div class="banner-img">
          <span v-if="images.length">
            <template v-for="(item, index) in images">
              <button
                v-show="index === 0"
                class="left_btn preview-img image-top-btn"
                @click="$preview.open(index, images, config)"
                style="cursor: pointer;"
              >
                更多图片
              </button>
            </template>
          </span>
          <span v-else>
            <button
              class="left_btn preview-img image-top-btn"
              @click="$message.warning('暂无图片')"
              style="cursor: pointer;"
            >
              更多图片
            </button>
          </span>
          <button class="right_btn image-top-btn" @click="moreVideo" style="cursor: pointer;">视频监控</button>
          <img v-if="images.length > 0" :src="images[0].src + '?imageView2/1/w/450/h/100'"/>
        </div>
        <template>
          <a-dropdown style="text-align: center; color: #ffffff; background: red;">
            <div
              class="warn-info ant-dropdown-link"
              v-show="currentItem.label !== 'DEPOT' && warningInfoList.length"
              style="cursor: pointer;"
            >
              预警信息
            </div>
            <a-menu slot="overlay">
              <template v-for="item in warningInfoList">
                <a-menu-item>
                  <div
                    @click="clickWarningInfo(item.id)"
                    :style="{ color: item.warningType === 'FAULT' ? '#f5222d' : '#1890ff' }"
                  >
                    <a-icon
                      :type="item.warningType === 'FAULT' ? 'warning' : 'info-circle'"
                      style="margin-right: 10px;"
                    />
                    {{ item.title }}
                  </div>
                </a-menu-item>
                <a-menu-divider/>
              </template>

              <a-menu-item key="index" style="text-align: center; font-weight: bold;" v-if="warningInfoList.length > 0">
                <div @click="clickMoreWarningInfo()">查看更多</div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <div class="p-12">
          <a-row :gutter="16" v-if="formItem.metrics">
            <a-col class="metrics-col" @click="clickMetrics(item)" style="cursor: pointer;" :span="12" v-for="(item, index) in formItem.metrics" :key="index">
              <metrics :value="item.value" :unit="item.unit" :name="item.name"></metrics>
            </a-col>
            <a-col
              class="metrics-col"
              :span="12"
              v-if="formItem.metrics.length > 0"
              @click="moreMetrics"
              style="cursor: pointer;">
              <metrics :value="moreMetricsString">
                <span slot="title"><a-icon type="bars"/></span>
              </metrics>
            </a-col>
          </a-row>
        </div>
        <a-divider style="margin: 10px 0 6px 0;"></a-divider>
        <div class="p-12 p-b-20">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-button block type="primary" @click="clickGatewayInfo">全景图</a-button>
            </a-col>
            <a-col :span="8">
              <a-button block type="primary" @click="clickEngineeringArchive">
                工程档案
              </a-button>
            </a-col>
            <a-col :span="8">
              <a-button block type="primary" @click="clickFlowChart">组态图</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
    <a-modal v-model="moreMetricsModal" :title="formItem.name" :width="bodyWidth - 200" :footer="null">
      <more-metrics-detail ref="moreMetricsRef"></more-metrics-detail>
    </a-modal>
    <a-modal :title="formItem.name + '的网关信息'" :footer="null" :width="bodyWidth - 400" v-model="gatewayModal">
      <device-gateway-info ref="gatewayInfoRef"></device-gateway-info>
    </a-modal>
    <a-modal :title="formItem.name + '的工程档案'" :footer="null" :width="bodyWidth" v-model="engineeringArchiveModal">
      <device-engineering-archive ref="engineeringArchiveRef"></device-engineering-archive>
    </a-modal>
    <a-modal title="预警信息管理" :footer="null" :width="1450" v-model="warningModal">
      <WarningTable ref="warningTableRef"></WarningTable>
    </a-modal>
    <a-modal :title="'预警信息详情'" :footer="null" :width="1000" v-model="warningDetailModal">
      <WarningDetail ref="warningDetailRef"></WarningDetail>
    </a-modal>
    <a-modal
      :title="'视频监控-' + formItem.name"
      @cancel="detailClose"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="detailDrawer"
      :bodyStyle="{padding:'0'}"
    >
      <a-tabs type="line" :activeKey="selectedType" @change="hKTabscallback">
        <a-tab-pane key="hkvideoPlay" tab="实时视频" :bodyStyle="{padding:'1'}">
          <h-k-player ref="hkPlayerRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-player>
        </a-tab-pane>
        <a-tab-pane key="hkvideoPlayBack" tab="视频回放" :bodyStyle="{padding:'1'}">
          <h-k-back-player ref="hkPlayerBackRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-back-player>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <a-modal
      title="工艺图"
      @cancel="closeChart"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="chartModal"
    >
      <DeviceFlowChart ref="" :technologyId="technologyId"></DeviceFlowChart>
    </a-modal>
    <a-modal
      :title="formItem.name+'-'+metricsTitle+'-历史数据'"
      :visible="metricsVisible"
      @cancel="handleCancel"
      :footer="null"
      :width="1200"
      :destroyOnClose="true"
    >
      <Detail ref="metricsRef" :unit=metricsUnit></Detail>
    </a-modal>
  </right-form-container>
</template>

<script>
  import moment from 'moment'
  import UUID from '../../../utils/UUID'
  import '../../../core/amap'
  import { mapState } from 'vuex'
  import SERVICE_URLS from '@/api/service.url'

  import containerClose from './mixins/container-close'
  import containerHeight from '../mixins/container-height'
  import RightFormContainer from './RightFormContainer'
  import { previewConfig } from '../../../utils/preview'
  import DeviceGatewayInfo from './DeviceGatewayInfo'
  import DeviceEngineeringArchive from './DeviceEngineeringArchive'
  import MoreMetricsDetail from '../../equipment2/monitoringPoint/MetricsMonitoring'
  import Metrics from './Metrics'
  import WarningTable from '../../equipment2/warning/Table'
  import WarningDetail from '../../equipment2/warning/Detail'
  import HKPlayer from '../../hkcamera/HKPlayer'
  import HKBackPlayer from '../../hkcamera/HKBackPlayer'

  import DeviceFlowChart from './DeviceConfiguration'
  import Detail from '../../equipment2/monitoringPoint/Detail'

  export default {
    name: 'DeviceDetail',
    components: {
      RightFormContainer,
      DeviceGatewayInfo,
      DeviceEngineeringArchive,
      MoreMetricsDetail,
      Metrics,
      WarningTable,
      WarningDetail,
      HKPlayer,
      DeviceFlowChart,
      Detail,
      HKBackPlayer
    },
    mixins: [containerClose, containerHeight],
    props: {
      mapId: {
        type: String,
        default: undefined
      }
    },
    data () {
      return {
        config: previewConfig,
        spinShow: false,
        currentItem: {},
        formItem: {},
        formatStr: 'YYYY/MM/DD HH:mm:ss',
        mapContainerId: new UUID().id,
        map: undefined,
        images: [],
        aggregationInfoByPointInfoAPI: SERVICE_URLS.equipment2.equipmentInfoApi.aggregationInfoByPoint,
        select: false,
        deviceSelect: [],
        gatewayModal: false,
        engineeringArchiveModal: false,
        moreMetricsModal: false,
        tempDeviceInfo: {
          id: '',
          name: ''
        },
        moreMetricsString: '更多指标',
        warningParams: {
          facilityId: '',
          deviceId: '',
          type: '',
          mapId: this.mapId,
          warningDealStatus: 'UN_PROCESSED'
        },
        warningInfoList: [],
        warningModal: false,
        warningDetailModal: false,
        detailDrawer: false,
        testCameraIndexCodes: [],
        technologyId: '2',
        chartModal: false,
        metricsTitle: '',
        metricsVisible: false,
        selectedType: 'hkvideoPlay',
        metricsUnit: ''
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth - 140
          return width > 1400 ? 1400 : width
        }
      })
    },
    methods: {
      moment,
      /**
       * @param item 左侧点击item
       */
      loadData (item) {
        if (item.id) {
          this.currentItem = {
            id: item.id
          }
        } else {
          this.currentItem = {
            id: item.key
          }
        }
        this.$http(this, {
          url: this.aggregationInfoByPointInfoAPI,
          noTips: true,
          loading: 'spinShow',
          params: {
            pointId: this.currentItem.id
          },
          success: (data) => {
            this.formItem = data.body
            this.imageForEach(this.formItem.sceneImages)
          }
        })
        if (item.label) this.currentItem.label = item.label
        this.getWarningInfo(item)
      },
      // 更多图片展示
      imageForEach (item) {
        this.images = []
        item.forEach((data) => {
          this.images.push({
            src: data.bucketUrl + data.fileUrl,
            msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
            w: data.w,
            h: data.h,
            title: data.name,
            id: data.id
          })
        })
      },
      getWarningInfo (item) {
        this.warningParams.deviceId = this.currentItem.id
        this.$http(this, {
          url: SERVICE_URLS.equipment2.warningApi.search_for_map,
          noTips: true,
          data: this.warningParams,
          success: (data) => {
            this.warningInfoList = data.body.content
          }
        })
      },
      clickWarningInfo (id) {
        this.warningDetailModal = true
        this.$nextTick(() => {
          this.$refs.warningDetailRef.span = 12
          this.$refs.warningDetailRef.loadData(id)
        })
      },
      clickMoreWarningInfo () {
        this.warningModal = true
        this.$nextTick(() => {
          this.$refs.warningTableRef.searchParams.pointId = this.currentItem.id
          this.$refs.warningTableRef.searchParams.mapId = this.mapId
          this.$refs.warningTableRef.search()
        })
      },
      moreVideo () {
        if (this.formItem.cameras.length > 0) {
          this.testCameraIndexCodes = []
          this.detailDrawer = true
          this.testCameraIndexCodes = this.formItem.cameras
          this.$nextTick(() => {
            this.$refs.hkPlayerRef.play()
          })
        } else {
          this.$message.warning('暂无视频')
        }
      },
      moreMetrics () {
        this.moreMetricsModal = true
        this.$nextTick(() => {
          this.$refs.moreMetricsRef.getListGroupByTag(this.currentItem.id)
        })
      },
      clickGatewayInfo () {
        if (this.formItem.panoramicUrl) {
          window.open(this.formItem.panoramicUrl)
        } else {
          this.$message.warning('暂无全景图')
        }

        // this.gatewayModal = true
        // this.$nextTick(() => {
        //   this.$refs.gatewayInfoRef.formItem = this.formItem
        // })
      },
      clickEngineeringArchive () {
        this.engineeringArchiveModal = true
        this.$nextTick(() => {
          this.$refs.engineeringArchiveRef.formItem = this.formItem
        })
      },
      detailClose () {
        this.detailDrawer = false
        this.$refs.hkPlayerRef.closeHandle()
        if (this.$refs.hkPlayerBackRef) this.$refs.hkPlayerBackRef.closeHandle()
        this.selectedType = 'hkvideoPlay'
      },
      clickFlowChart () {
        if (this.formItem.hasConfigurationDiagram) {
          window.open('/equipment2/technology/3d' + '?pointId=' + this.currentItem.id)
        } else if (this.formItem.name === '第三污水处理厂') {
          // TODO 演示临时添加
          window.open('https://app.jichuanzhongke.com/login?op=auto')
        } else {
          this.$message.warning('暂无组态图')
        }
      },
      closeChart () {
        this.chartModal = false
      },
      clickMetrics (item) {
        this.metricsTitle = item.name
        this.metricsUnit = item.unit
        this.metricsVisible = true
        this.$nextTick(() => {
          this.$refs.metricsRef.realId = item.deviceId
          this.$refs.metricsRef.handleMetricsHistory()
        })
      },
      handleCancel () {
        this.metricsVisible = false
      },
      hKTabscallback (key) {
        this.selectedType = key
        if (key === 'hkvideoPlay') {
          this.$nextTick(() => {
            this.$refs.hkPlayerBackRef.closeHandle()
          })
          this.$refs.hkPlayerRef.play()
        } else {
          this.$refs.hkPlayerRef.closeHandle()
          this.$nextTick(() => {
            this.$refs.hkPlayerBackRef.play()
          })
        }
      }
    }
  }
</script>
<style scoped>
  .banner-img {
    width: 100%;
    position: relative;
  }

  .image-top-btn {
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(76, 69, 69);
    border: none;
    border-radius: 2px;
    outline: none;
    text-align: center;
  }

  .left_btn {
    position: absolute;
    left: 32px;
    top: 50px;
  }

  .right_btn {
    position: absolute;
    right: 35px;
    top: 50px;
  }
</style>
