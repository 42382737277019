/* eslint-disable */

const hkPlayerResize = {
  mounted () {
    const { playerContainerId, width, height } = this
    // 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
    $(window).resize(() => {
      if (this.oWebControl != null) {
        this.oWebControl.JS_Resize(width, height)
        if ($('#' + playerContainerId).get(0)) {
          this.setWndCover()
        }
      }
    })

    // 监听滚动条scroll事件，使插件窗口跟随浏览器滚动而移动
    $(window).scroll(() => {
      if (this.oWebControl != null) {
        this.oWebControl.JS_Resize(width, height)
        if ($('#' + playerContainerId).get(0)) {
          this.setWndCover()
        }
      }
    })
  },
  methods: {
    setWndCover () {
      const { oWebControl, playerContainerId, width, height } = this
      var iWidth = $(window).width()
      var iHeight = $(window).height()
      var oDivRect = $('#' + playerContainerId).get(0).getBoundingClientRect()

      var iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0
      var iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0
      var iCoverRight = oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0
      var iCoverBottom = oDivRect.bottom - iHeight > 0 ? Math.round(oDivRect.bottom - iHeight) : 0

      iCoverLeft = iCoverLeft > 1000 ? 1000 : iCoverLeft
      iCoverTop = iCoverTop > 600 ? 600 : iCoverTop
      iCoverRight = iCoverRight > 1000 ? 1000 : iCoverRight
      iCoverBottom = iCoverBottom > 600 ? 600 : iCoverBottom

      oWebControl.JS_RepairPartWindow(0, 0, 1001, 600) // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft !== 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, 600)
      }
      if (iCoverTop !== 0) {
        oWebControl.JS_CuttingPartWindow(0, 0, 1001, iCoverTop) // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight !== 0) {
        oWebControl.JS_CuttingPartWindow(1000 - iCoverRight, 0, iCoverRight, 600)
      }
      if (iCoverBottom !== 0) {
        oWebControl.JS_CuttingPartWindow(0, 600 - iCoverBottom, 1000, iCoverBottom)
      }
    }
  }
}

export default hkPlayerResize
