const overlayGroupConfig = {
  data () {
    return {
      groupConfig: [ { key: '集污池', isCluster: true }, { key: '三格化粪池', isCluster: true }, { key: '泵站', isCluster: true }, { key: '一体化泵站', isCluster: true }, { key: '提升泵站', isCluster: true }, { key: '污水处理站', isCluster: true }, { key: '污水处理厂', isCluster: true }, { key: '净化槽', isCluster: true }, { key: '管网', minZoom: 10, maxZoom: 20 } ],
      districtGroupConfig: { 'province': { minZoom: 0, maxZoom: 7 }, 'city': { minZoom: 8, maxZoom: 8 }, 'district': { minZoom: 9, maxZoom: 12 } },
      districtColor: ['#D8B4FC', '#C3FCD1', '#FFB1E3', '#FDFBBE', '#FFCBEF', '#E7CFFF']
    }
  },
  methods: {
    findOverlayGroupConfig (groupKey) {
      var keys = groupKey.split('_');
     // console.log("groupKey:" + groupKey);
      if (keys.length > 1) {
        return this.groupConfig.find((group) => group.key === keys[1])
      } else {
        return null;
      }
    },
    getDistrictGroupConfig (level) {
      return this.districtGroupConfig[level]
    },
    getDistrictGroupColor (index) {
      var n = index % this.districtColor.length
      if (n < this.districtColor.length) {
        return this.districtColor[n]
      } else {
        return this.districtColor[0]
      }
    }
  },
}
export default overlayGroupConfig
