<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body p-16 p-b-4">
      <div class="center">
        <a-radio-group defaultValue="-1" buttonStyle="solid" @change="onSearch" v-model="searchParams.status">
          <a-radio-button :value="item.value" v-for="(item, index) in userStatus" :key="index">{{
            item.label
          }}</a-radio-button>
        </a-radio-group>
      </div>
      <a-list :bordered="false" :dataSource="dataSource">
        <a-list-item
          style="cursor: pointer"
          slot="renderItem"
          @click.native="itemClick(item)"
          class=" default-hover"
          slot-scope="item"
        >
          <a-col :span="2">
            <a-icon type="user" />
          </a-col>
          <a-col :span="17"> {{ item.name }}（{{ item.mobilePhoneNumber }}） </a-col>
          <a-col :span="5" style="text-align: right;">
            <a-tag color="green" v-if="item.status === 0">在线</a-tag>
            <a-tag v-if="item.status === 1">离线</a-tag>
          </a-col>
        </a-list-item>
        <div slot="footer">
          <a-pagination
            size="small"
            :current="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="pagination.showTotal"
            @change="onPageChange"
          />
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import searchListCommon from '../mixins/search-list-common'

export default {
  name: 'SearchXunjianList',
  props: {
    mapId: {
      type: String,
      default: undefined,
    },
  },
  mixins: [searchListCommon],
  data() {
    return {
      userStatus: [
        {
          label: '全部',
          value: -1,
        },
        {
          label: '在线',
          value: 0,
        },
        {
          label: '离线',
          value: 1,
        },
      ],
      searchParams: {
        page: 0,
        size: 10,
        direction: '',
        order: '',
        mapInfoId: this.mapId,
        name: '',
        status: -1,
      },
      dataSource: [],
      listType: 'xunjian',
    }
  },
  methods: {
    /**
     * 执行数据加载
     */
    loadData() {
      if (this.spinShow) return
      this.$emit('onSearchStart')
      this.$http(this, {
        url: SERVICE_URLS.xj.xjUserRealInfo.search,
        data: this.searchParams,
        noTips: true,
        loading: 'spinShow',
        success: (data) => {
          this.dataSource = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = this.searchParams.page + 1
          this.$emit('onSearchSuccess')
        },
        error: () => {
          this.$emit('onSearchError')
          this.$message.error('巡检人员列表检索失败！')
        },
      })
    },

    /**
     * searchKey发生变化 watch会执行当前方法
     * @param value 变化值
     */
    searchKeyChange(value) {
      this.searchParams.name = value
    },

    /**
     * 列表点击事件
     * @param item 当前列值
     */
    itemClick(item) {
      this.$emit('onItemClick', this.listType, item)
    },
  },
}
</script>
