<template>
  <div class="page-header-index-wide xunjian-history-list">
    <div class="container-main">
      <div class="container-left">
        <div class="m-r-24">
          <a-form layout="horizontal">
            <a-form-item label="车辆名称" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-select
                disabled
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfo"
                placeholder="请选择"
                @change="search"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="开始时间" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%;" @change="onChangeStartTime"/>
            </a-form-item>
            <a-form-item label="结束时间" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%;" @change="onChangeEndTime"/>
            </a-form-item>
            <a-form-item style="text-align: center;">
              <a-button type="primary" style="width: 100%;" @click="search">查询</a-button>
            </a-form-item>
          </a-form>
          <a-divider style="margin: 10px 0 6px 0;"></a-divider>
        </div>
        <div>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{ statisticsData.records || '0' }}
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                任务次数
              </div>
            </a-col>
            <a-col :span="10" :offset="2">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{ statisticsData.mileages.toFixed(2) || '0' }} km
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                总公里数
              </div>
            </a-col>
          </a-row>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{ statisticsData.durations || '0' }}
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                任务时间
              </div>
            </a-col>
            <a-col :span="10" :offset="2">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{
                statisticsData.carInfo.offline === '1'
                ? '静止'
                : statisticsData.carInfo.offline === '2'
                ? '行驶'
                : '离线'
                }}
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                当前状态
              </div>
            </a-col>
          </a-row>
          <a-row type="flex" style="margin-top: 15px;">
            <a-col :span="10">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{ statisticsData.carInfo.platNumber || '-' }}
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                车辆牌号
              </div>
            </a-col>
            <a-col :span="10" :offset="2" v-if="statisticsData.carInfo.offline!=='0'">
              <div style="font-size: 22px; text-align: center; color: #656565;">
                {{ statisticsData.carInfo.speed || '-' }} km/h
              </div>
              <div style="font-size: 14px; text-align: center; color: #656565;">
                当前速度
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="container-right p-l-24">
        <a-table
          size="middle"
          :columns="columns"
          :dataSource="tableData"
          :loading="tableLoading"
          :pagination="pagination"
          @change="tableChange"
          :customRow="rowClick"
          :rowKey="(record) => record.id"
          bordered
        >
          <template slot="_index" slot-scope="text, record, index">
            {{ searchParams.number * searchParams.size + index + 1 }}
          </template>
          <template slot="status" slot-scope="text, record">
            <a-tag v-if="record.status === 'NOT'">待执行</a-tag>
            <a-tag v-if="record.status === 'DOING'" color="#87d068">执行中</a-tag>
            <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
          </template>
          <template slot="type" slot-scope="text, record">
            <span v-if="record.type === 'GIVEN'">下达</span>
            <span v-if="record.type === 'AUTO'">自主</span>
          </template>
          <template slot="executeTime" slot-scope="text, record">
            <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
          </template>
        </a-table>
      </div>
      <!--车辆任务详情Model-->
      <a-modal
        :title="platNumber"
        :width="1400"
        :footer="null"
        :visible="recordDetailModal"
        @cancel="closeRecordDetailModal"
      >
        <record-detail ref="recordDetailRef" :dbDevicesData="dbDevicesData"></record-detail>
      </a-modal>
    </div>
  </div>
</template>

<script>
  import entityCRUD from '../../common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import { historyColumns } from './common/historyCommon'
  import RecordDetail from '../record/Detail'
  import moment from 'moment'
  import mapDevices from '../../map/composite/mixins/map-devices'

  export default {
    components: { RecordDetail },
    mixins: [entityCRUD, mapDevices],
    name: 'HistoryList',
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.car.carRecordApi,
        initColumns: historyColumns(),
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
        carList: [],
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          carInfo: '',
          startTime: '',
          endTime: ''
        },
        statisticsData: {}, //车辆统计信息
        recordDetailModal: false,
        platNumber: ''
      }
    },
    created () {
    },
    mounted () {
      this.loadCarInfoList()
    },
    methods: {
      onChangeStartTime (date, dateString) {
        this.searchParams.startTime = moment(dateString).format('YYYY-MM-DD')
        this.search()
        this.loadCarStatistics()
      },
      onChangeEndTime (date, dateString) {
        this.searchParams.endTime = moment(dateString).format('YYYY-MM-DD')
        this.search()
        this.loadCarStatistics()
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadCarStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.statistics,
          data: {
            carInfo: this.searchParams.carInfo,
            startTime: this.searchParams.startTime,
            endTime: this.searchParams.endTime
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },
      searchRecord (id) {
        this.searchParams.carInfo = id
        this.search()
        this.loadCarStatistics()
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.platNumber = '车牌号：' + record.carInfo.platNumber
              this.recordDetailModal = true
              this.$nextTick(() => {
                this.$refs.recordDetailRef.loadData(record.id)
              })
            }
          }
        }
      },
      closeRecordDetailModal () {
        this.recordDetailModal = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;

    &.mobile {
      display: block;

      .account-settings-info-left {
        border-right: unset;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        height: 50px;
        overflow-x: auto;
        overflow-y: scroll;
      }

      .account-settings-info-right {
        padding: 20px 40px;
      }
    }

    .account-settings-info-left {
      border-right: 1px solid #e8e8e8;
      width: 324px;
    }

    .account-settings-info-right {
      flex: 1 1;
      padding: 8px 40px;

      .account-settings-info-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 12px;
      }

      .account-settings-info-view {
        padding-top: 12px;
      }
    }
  }
</style>
