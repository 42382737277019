<template>
  <div id="fs_area01"></div>
</template>

<script>
  import G2 from '@antv/g2'
  import { DataSet } from '@antv/data-set'

  export default {
    name: 'Area01',
    props: {
      // 数据源
      data: {
        type: Array,
        default: () => []
      },
      // ↓y轴标题
      yText: {
        type: String,
        default: 'y轴别名'
      },
      // ↓x轴Key值，默认'xKey'
      xName: {
        type: String,
        request: true
      },
      // ↓y轴Key值，默认'yKey'
      yName: {
        type: String,
        request: true
      },
      // x轴显示的密度，默认6个左右
      xDensity: {
        type: Number,
        default: 6
      },
      // ↓高度
      height: {
        type: Number,
        request: true
      },
      // ↓面积图顶部颜色
      topColor: {
        type: String,
        default: '#2b85e4'
      },
      // ↓面积图底部颜色
      bottomColor: {
        type: String,
        default: '#c6e9ff'
      },
      // ↓时间格式
      timeFormat: {
        type: String,
        default: 'YYYY-MM-DD HH:mm'
      },
      // ↓透明度
      opacity: {
        type: Number,
        default: 0.85
      }
    },
    data () {
      return {
        minMaxDate: {
          minDate: new Date().getTime(),
          maxDate: new Date().getTime()
        },
        sliderDiv: null,
        container: null,
        ds: null,
        dv: null,
        chart: null,
        scale: null,
        view: null
      }
    },
    mounted () {
      this.minMaxDate = this.getMinMaxDate()
      this.init()
    },
    watch: {
      data () {
        this.handleDataChange()
      }
    },
    methods: {
      getMinMaxDate () {
        let data = this.data
        let minDate = null
        let maxDate = null
        if (data.length) {
          minDate = new Date(data[0].collectTime).getTime()
          maxDate = new Date(data[0].collectTime).getTime()
        } else {
          return {
            minDate: new Date().getTime(),
            maxDate: new Date().getTime()
          }
        }
        data.forEach(item => {
          let itemTime = new Date(item.collectTime).getTime()
          if (itemTime < minDate) minDate = itemTime
          if (itemTime > maxDate) maxDate = itemTime
        })
        return { minDate, maxDate }
      },
      init () {
        this.createScale()
        this.createContainer()
        this.createDataSet()
        this.createDataView()
        this.createChart()
        this.createView()
        this.addSlider()
      },
      createContainer () {
        let root = document.getElementById('fs_area01')
        this.sliderDiv = document.createElement('div')
        this.container = document.createElement('div')
        root.appendChild(this.container)
        root.appendChild(this.sliderDiv)
      },
      createDataSet () {
        this.ds = null
        this.ds = new DataSet({
          state: {
            start: this.minMaxDate.minDate,
            end: this.minMaxDate.maxDate
          }
        })
      },
      createDataView () {
        let _this = this
        this.dv = null
        this.dv = this.ds.createView().source(this.data)
        this.dv.transform({
          type: 'filter',
          callback (obj) {
            const time = new Date(obj.collectTime).getTime() // !注意：时间格式，建议转换为时间戳进行比较
            return time >= _this.ds.state.start && time <= _this.ds.state.end
          }
        })
      },
      createChart () {
        this.chart = null
        this.chart = new G2.Chart({
          container: this.container,
          forceFit: true,
          height: this.height,
          padding: [ 40, 40, 40, 80 ],
          animate: false
        })
        this.chart.axis(this.xName, {
          grid: null
        })
        this.chart.axis(this.yName, {
          title: true
        })
        this.chart.legend(false)
      },
      createView () {
        this.view = null
        this.view = this.chart.view()
        this.view.source(this.dv, this.scale)
        this.view.area()
          .position(`${this.xName}*${this.yName}`)
          .color(`l(100) 0:${this.topColor} 1:${this.bottomColor}`)
          .opacity(this.opacity)
        this.chart.render()
      },
      createScale () {
        this.scale = {
          collectTime: {
            type: 'time',
            tickCount: this.xDensity,
            mask: this.timeFormat
          },
          collectValue: {
            alias: this.yText
          }
        }
      },
      resetDivAndData () {
        let root = document.getElementById('fs_area01')
        root.removeChild(this.sliderDiv)
        root.removeChild(this.container)
        this.sliderDiv = null
        this.container = null
        this.ds = null
        this.dv = null
        this.chart = null
        this.view = null
      },
      addSlider () {
        let _this = this
        // 创建 Slider
        this.chart.interact('slider', {
          container: this.sliderDiv,
          data: this.dv.rows,
          start: this.ds.state.start, // 和状态量对应
          end: this.ds.state.end,
          scales: this.scale,
          xAxis: this.xName,
          yAxis: this.yName,
          backgroundChart: {
            type: 'line',
            color: 'grey'
          },
          onChange (ev) {
            const { startValue, endValue } = ev
            _this.ds.setState('start', startValue)
            _this.ds.setState('end', endValue)
          }
        })
      },
      handleDataChange () {
        this.resetDivAndData()
        this.minMaxDate = this.getMinMaxDate()
        this.init()
      }
    }
  }
</script>

<style scoped>

</style>