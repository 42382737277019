<template>
  <right-form-container :title="currentItem.name + '的巡检记录'" v-model="visible" @close="close">
    <a-spin :spinning="spinShow" :delay="200">
      <div
        v-malihu-scrollbar
        class="mCustomScrollbar"
        style="overflow-y: auto;"
        :style="{ 'max-height': bodyHeight + 'px' }"
      >
        <div class="p-12">
          <a-form layout="inline">
            <a-row>
              <a-form-item label="所属部门">
                <span>{{ formItem.deptName }}</span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item label="巡检时间" style="margin-right: 0;">
                <span>{{ moment(formItem.startTime).format(formatStr) }}</span>
                <span>&nbsp;~&nbsp;</span>
                <span v-if="formItem.online">至今</span>
                <span v-if="!formItem.online">{{ moment(formItem.endTime).format(formatStr) }}</span>
              </a-form-item>
            </a-row>
          </a-form>
          <div :id="mapContainerId" class="xunjian-track"></div>
        </div>
        <div class="p-12 p-t-24">
          <a-row :gutter="16">
            <a-col :span="12">
              <metrics value="巡检时长"
                ><span slot="title">{{ formItem.spendTime }}</span></metrics
              >
            </a-col>
            <a-col :span="12">
              <metrics value="巡检里程"
                ><span slot="title">{{ formItem.mileage }}</span></metrics
              >
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12">
            <a-col :span="12">
              <metrics value="正常设备数量"
                ><span slot="title">{{(formItem.normalAmount === 0 || formItem.normalAmount === null) ? '无' : formItem.normalAmount }}</span></metrics
              >
            </a-col>
            <a-col :span="12">
              <metrics value="异常设备数量"
                ><span slot="title">{{(formItem.abnormalAmount === 0 || formItem.abnormalAmount === null) ? '无' : formItem.abnormalAmount }}</span></metrics
              >
            </a-col>
          </a-row>
        </div>
        <a-divider style="margin: 10px 0 6px 0;"></a-divider>
        <div class="p-12 p-b-20">
          <a-row :gutter="16">
            <a-col :span="8" v-if="formItem.online">
              <a-button block type="primary" icon="environment" @click="xunjianPeopleFit">人员追踪</a-button>
            </a-col>
            <a-col :span="formItem.online ? 8 : 12">
              <a-button block type="primary" icon="sync" @click="openPassedModal">轨迹回放</a-button>
            </a-col>
            <a-col :span="formItem.online ? 8 : 12">
              <a-button block type="primary" icon="database" @click="openHistoryModal">历史记录</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
    <a-modal
      :title="currentItem.name + '的巡检轨迹信息'"
      :maskClosable="false"
      :footer="null"
      :width="bodyWidth - 200"
      v-model="passedModal"
      centered
    >
      <xunjian-passed ref="passedRef" :dbDevicesData="dbDevicesData" :formItem="formItem"></xunjian-passed>
    </a-modal>
    <a-modal
      :title="currentItem.name + '的巡检历史记录'"
      :maskClosable="false"
      :footer="null"
      :width="bodyWidth"
      v-model="historyModal"
    >
      <xunjian-history-list
        ref="historyListRef"
        :dbDevicesData="dbDevicesData"
        :userId="this.currentItem.userId"
      ></xunjian-history-list>
    </a-modal>
    <div class="hide">{{ mapStyle }}</div>
  </right-form-container>
</template>

<script>
import moment from 'moment'
import UUID from '../../../utils/UUID'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import '../../../core/amap'
import { mapState } from 'vuex'

import SERVICE_URLS from '@/api/service.url'

import containerClose from './mixins/container-close'
import containerHeight from '../mixins/container-height'
import xunjianPassed from './mixins/xunjian-passed'
import mapStyle from './mixins/map-style'

import RightFormContainer from './RightFormContainer'
import XunjianHistoryList from './XunjianHistoryList'
import XunjianPassed from './XunjianPassed'
import Metrics from './Metrics'

export default {
  name: 'XunjianDetail',
  components: { XunjianPassed, RightFormContainer, XunjianHistoryList, Metrics },
  mixins: [containerClose, containerHeight, xunjianPassed, mapStyle],
  data() {
    return {
      spinShow: false,
      currentItem: {},
      formItem: {},
      formatStr: 'YYYY/MM/DD HH:mm:ss',
      mapContainerId: new UUID().id,
      map: undefined,
      // 巡检历史记录
      passedModal: false,
      historyModal: false,
    }
  },
  computed: {
    ...mapState({
      bodyWidth: (state) => {
        const width = state.app.bodyWidth - 140
        return width > 1400 ? 1400 : width
      },
    }),
  },
  methods: {
    moment,
    /**
     * 加载用户最近一个轨迹
     * @param item 左侧点击item
     */
    loadData(item) {
      this.currentItem = item
      this.clearTrack()
      this.$http(this, {
        url: SERVICE_URLS.xj.xjRecord.find_detail_for_map,
        params: {
          userId: this.currentItem.userId,
        },
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.formItem = data.body
          this.buildMap()
        },
      })
    },

    /**
     * 创建Map对象
     */
    buildMap() {
      if (!this.map) {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.map = new AMap.Map(this.mapContainerId, {
            mapStyle: 'amap://styles/normal',
            resizeEnable: true,
            center: [106.230909, 38.487193],
            zoom: 11,
          })
          this.buildMapOtherLayer()
          this.serTrackInfo()
          this.buildTrack()
          var me = this
          this.map.on('mouseout', function () {
            if (me.removeOverMarkTips) {
              me.removeOverMarkTips()
            }
          })
        })
      } else {
        this.serTrackInfo()
        this.buildTrack()
      }
    },

    /**
     * 打开历史记录modal
     */
    openHistoryModal() {
      this.historyModal = true
      this.$nextTick(() => {
        this.$refs.historyListRef.restAndOnSearch()
      })
    },

    /**
     * 打开轨迹回放
     */
    openPassedModal() {
      this.passedModal = true
      this.$nextTick(() => {
        this.$refs.passedRef.init()
      })
    },

    /**
     * 在地图上定位巡检人员
     */
    xunjianPeopleFit() {
      if (this.formItem.online) {
        this.$emit('onPeopleFit', this.currentItem.userId)
      }
    },

    serTrackInfo() {
      // 经纬度数组
      this.track = this.formItem.track
      // 在线状态
      this.online = this.formItem.online
      // 距离
      this.mileage = this.formItem.mileage
    },

    close() {
      this.visible = false
      this.$emit('input', this.visible)
      this.$emit('close')
      this.map = null
    },
  },
}
</script>
