import { mapState } from 'vuex'

const containerHeight = {

  computed: {
    ...mapState({
      bodyHeight: state => {
        return state.app.bodyHeight - 140
      }
    })
  }
}

export default containerHeight