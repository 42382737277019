<template>
  <div>
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="预警对象">
            <a-input v-model="searchParams.deviceName" placeholder="请输入预警对象" allowClear/>
          </a-form-item>
          <a-form-item label="处理状态">
            <a-select allowClear v-model="searchParams.warningDealStatus" @change="search" placeholder="请选择处理状态">
              <a-select-option v-for="item in warningDealStatusList" :key="item.key" :value="item.key"
              >{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="预警类型">
            <a-select allowClear v-model="searchParams.warningType" @change="search" placeholder="请选择预警类型">
              <a-select-option v-for="item in warningTypeList" :key="item.key" :value="item.key"
              >{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
              {{ expand ? '收起' : '展开' }}
              <a-icon :type="expand ? 'up' : 'down'"/>
            </a>
          </a-form-item>
          <a-row v-if="expand" style="margin-top: 10px;">
            <a-form-item label="预警时间">
              <a-range-picker
                :showTime="{ format: 'HH:mm' }"
                :allowClear="true"
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD"
                @ok="handleModalPickerOk"
              ></a-range-picker>
            </a-form-item>
          </a-row>
        </a-form>
      </div>
    </div>
    <a-table
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
      :pagination="pagination"
      @change="tableChange"
      :customRow="handleView"
      :rowKey="(record) => record.id"
      bordered
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.page * searchParams.size + index + 1 }}
      </template>
      <template slot="collectTime" slot-scope="text, record">
        {{ moment(record.collectTime).format('YYYY-MM-DD HH:mm:ss')||'-' }}
      </template>
      <template slot="dealEndTime" slot-scope="text, record">
        {{record.dealEndTime?moment(record.dealEndTime).format('YYYY-MM-DD HH:mm:ss'):'-'}}
      </template>
      <template slot="currentMetricsValue" slot-scope="text, record">
        {{formatCurrentMetricsValue(record)}}
      </template>
      <template slot="warningType" slot-scope="text, record">
        <a-tag color="blue" v-if="record.warningType === 'WARNING'" class="a-tag">预警</a-tag>
        <a-tag color="red" v-else class="a-tag">故障</a-tag>
      </template>
      <template slot="warningDealStatus" slot-scope="text, record">
        <span v-if="record.warningDealStatus === 'UN_PROCESSED'">未处理</span>
        <span v-if="record.warningDealStatus === 'PROCESSED'">已处理</span>
      </template>

      <template slot="action" slot-scope="text, record">
        <a type="link" @click="handleEdit(record.id)" @click.stop v-if="record.warningDealStatus === 'UN_PROCESSED'">处理</a>
        <span v-else>已处理</span>
      </template>
    </a-table>
    <a-modal
      title="处理预警信息"
      :width="580"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal"
    >
      <warning-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></warning-form>
    </a-modal>

    <a-drawer
      title="预警详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="550px"
      :visible="detailDrawer"
      :mask="false"
    >
      <warning-detail ref="viewRef"></warning-detail>
    </a-drawer>
  </div>
</template>
<script>
  import entityCRUD from '../../common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import { warningColumns } from './common/common'
  import common from './common/common'
  import WarningDetail from './Detail'
  import WarningForm from './Form'
  import moment from 'moment'

export default {
  name: 'List',
  components: { WarningDetail, WarningForm },
  mixins: [entityCRUD, common],
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.warningApi,
      searchParams: {
        page: 0,
        size: 10,
        direction: 'DESC',
        order: 'dateCreated',
        deviceName: '',
        content: '',
        startTime: undefined,
        endTime: undefined,
        departmentId: '',
        warningDealStatus: 'UN_PROCESSED',
        warningType: undefined,
        facilityId: undefined,
        mapId: '',
      },
      initColumns: warningColumns(),
      orgTree: [],
      detailDrawer: false,
      warningDealStatusList: [
        {
          key: 'UN_PROCESSED',
          label: '未处理',
        },
        {
          key: 'PROCESSED',
          label: '已处理',
        },
      ],
      warningTypeList: [
        {
          key: 'WARNING',
          label: '预警',
        },
        {
          key: 'FAULT',
          label: '故障',
        },
      ],
      facilityList: [],
      expand: false,
    }
  },
  methods: {
    toggle () {
      this.expand = !this.expand
    },
    moment,
    handleModalPickerOk(value) {
      this.searchParams.startTime = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchParams.endTime = value[1].format('YYYY-MM-DD HH:mm:ss')
      this.search()
    },
    handleView(record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              this.$refs.viewRef.loadData(record.id)
              this.$refs.viewRef.span = 24
            })
          },
        },
      }
    },
    setSearchParamsPage(pagination) {
      this.searchParams.page = pagination.current - 1
      this.searchParams.size = pagination.pageSize
    },
  },
}
</script>

<style scoped>
  .a-tag {
    margin-right: 0px;
  }
</style>
