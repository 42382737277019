<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="span"><span>预警对象：</span>{{ formItem.pointName || '-' }}</a-col>
        <a-col :span="span"><span>预警内容：</span>{{ formItem.title || '-' }}</a-col>
        <a-col :span="span"><span>指标值：</span> {{ formatCurrentMetricsValue(formItem) }}</a-col>
<!--        <a-col :span="span"><span>预警值：</span> {{ formItem.warningMetricsValue || '0.0' }}</a-col>-->
<!--        <a-col :span="span"><span>指标范围：</span> {{ formItem.metricsRange || '-' }}</a-col>-->
        <a-col :span="span"
          ><span>采集时间：</span> {{ moment(formItem.collectTime).format('YYYY-MM-DD HH:mm:ss') }}</a-col
        >
        <a-col :span="span"><span>持续时间：</span> {{ formItem.warningLastTime || '-' }}</a-col>
        <a-col :span="span"><span>类型：</span> {{ formItem.warningType === 'WARNING' ? '预警' : '故障' }}</a-col>
        <a-col :span="span"
          ><span>处理时间：</span
          >{{ formItem.dealEndTime ? moment(formItem.dealEndTime).format('YYYY-MM-DD HH:mm:ss') : '-' }}</a-col
        >
        <a-col :span="span"><span>处理人：</span>{{ formItem.dealUserName || '-' }}</a-col>
        <a-col :span="span"
          ><span>处理状态：</span>{{ formItem.warningDealStatus === 'UN_PROCESSED' ? '未处理' : '已处理' }}</a-col
        >
        <a-col :span="span"><span>备注：</span>{{ formItem.remark || '-' }}</a-col>
      </a-row>
    </div>
  </a-skeleton>
</template>

<script>
import { warningFormVO } from './common/common'
import SERVICE_URLS from '@/api/service.url'
import moment from 'moment'
import common from './common/common'

export default {
  name: 'WarningDetail',
  mixins: [common],
  components: {},
  data() {
    return {
      ifSub: false,
      formItem: warningFormVO(),
      span: '',
    }
  },
  methods: {
    moment,
    loadData(id) {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.warningApi.view,
        params: { id },
        noTips: true,
        success: (data) => {
          Object.assign(this.formItem, data.body)
          this.$emit('loactionWarnInfo', data.body)
        },
      })
    }
  },
}
</script>

<style scoped></style>
