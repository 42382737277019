<template>
    <!--  <a-dropdown :visible="visible" :trigger="['hover']" @click.native="visible = !visible">-->
    <a-dropdown v-model="visible" :trigger="['hover']">
        <div class="drop-container" :style="dropdownContainerStyle">
            <div class="ant-modal-title drop-container-title">
                <div class="cursor-pointer ant-dropdown-link">{{title}}</div>
            </div>
        </div>
<!--        <a-menu slot="overlay" :style="menuTreeData.length >1 ?'height: 400px; overflow: auto;':''" v-if="menuTreeData.length>0">-->
          <a-menu slot="overlay" :style="menuTreeData.length >1 ?'max-height: 400px; overflow: auto;':''" v-if="menuTreeData.length>0">
            <template v-for="(item,index) in menuTreeData">
                <a-menu-item :key="item.value">
                    <a @click="onClick(item)" :class="[checkSelected(item)?'check':'']"
                       :style="item.pId?menuItemStyle:null">{{item.label}}
                        &nbsp;&nbsp;<a-icon type="check" v-if="checkSelected(item)"
                                            style="color: #1e88e5;margin-top: 4px"
                                            class="f-r"/>
                    </a>
                </a-menu-item>
                <a-menu-divider v-if="index !== menuTreeData.length -1"></a-menu-divider>
            </template>
        </a-menu>
        <a-menu slot="overlay" v-else>
            <a-menu-item>
                <div style="width: 100%;text-align: center;">暂无</div>
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
  export default {
    name: 'MenuTree',
    props: {
      /**
       * { label:'',value:'',pId:''}
       */
      menuTreeData: {
        type: Array,
        default: () => {
          return []
        }
      },
      selectedValue: {
        type: Array,
        default: () => {
          return []
        }
      },
      dropWidth: {
        type: Number,
        default: 130
      },
      title: {
        type: String,
        default: '图层'
      },
      dropdownContainerStyle: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        menuItemStyle: {
          'margin-left': '16px'
        },
        visible: false
      }
    },
    computed: {
      checkSelected () {
        return function (item) {
          return this.selectedValue.findIndex(x => x === item.value) !== -1
        }
      }
    },
    methods: {
      // 点击判断是第一级 还是第二级  第一级被点击 默认选中第二级所有
      // 第二级点击 判断是否全部选中 设置第一级选中状态
      onClick (item) {
        const index = this.selectedValue.findIndex(x => x === item.value)
        if (index !== -1) {
          this.selectedValue.splice(index, 1)
          // 不存在pId 需要处理子节点
          if (!item.pId) {
            this.menuTreeData.forEach(x => {
              if (x.pId === item.value) {
                const pIndex = this.selectedValue.findIndex(c => c === x.value)
                if (pIndex !== -1) {
                  this.selectedValue.splice(pIndex, 1)
                }
              }
            })
          } else { // 需要处理父节点
            // 验证是否全部选中 只要有一个未选中 移除 父节点选中功能
            let temp = true
            this.menuTreeData.forEach(x => {
              if (x.pId === item.pId) {
                const pIndex = this.selectedValue.findIndex(c => c === x.value)
                if (pIndex === -1) {
                  temp = false
                }
              }
            })
            if (!temp) {
              const selectIndex = this.selectedValue.findIndex(x => x === item.pId)
              if (selectIndex !== -1) {
                this.selectedValue.splice(selectIndex, 1)
              }
            }
          }
        } else {
          this.selectedValue.push(item.value)
          // 不存在pId 需要处理子节点
          if (!item.pId) {
            this.menuTreeData.forEach(x => {
              if (x.pId === item.value) {
                const pIndex = this.selectedValue.findIndex(c => c === x.value)
                if (pIndex === -1) {
                  this.selectedValue.push(x.value)
                }
              }
            })
          } else { // 需要处理父节点
            // 验证是否全部选中 只要有一个未选中 移除 父节点选中功能
            let temp = true
            this.menuTreeData.forEach(x => {
              if (x.pId === item.pId) {
                const pIndex = this.selectedValue.findIndex(c => c === x.value)
                if (pIndex === -1) {
                  temp = false
                }
              }
            })
            if (temp) {
              const selectIndex = this.selectedValue.findIndex(x => x === item.pId)
              if (selectIndex === -1) {
                this.selectedValue.push(item.pId)
              }
            }
          }
        }
        this.$emit('onChange', this.selectedValue)
      }
    }
  }
</script>