<template>
  <a-select placeholder="请选择月份" allowClear style="width: 150px" @change="onChange" v-model="value">
    <a-select-option v-for="(item,index) in month" :key="index" :value="item">
      {{item}}月
    </a-select-option>
  </a-select>
</template>
<script>
  export default {
    data () {
      return {
        month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        value: undefined
      }
    },
    methods: {
      onChange (e) {
        this.value = e
        this.$emit('onChange', e)
      }
    }
  }
</script>