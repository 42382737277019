<template>
  <div :id="id"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'bar',
    props: {
      data: Array,
      id: String
    },
    data () {
      return {
        count: this.dataCount(),
        color: []
      }
    },
    mounted () {
      this.initChart()
    },
    methods: {
      dataCount () {
        var s = 0
        for (var i = 0; i < this.data.length; i++) {
          s += this.data[i].count
        }
        return s
      },
      initChart () {
        this.dataCount()
        // 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
        const sliceNumber = 0.01

        // 自定义 other 的图形，增加两条线
        G2.Shape.registerShape('interval', 'sliceShape', {
          draw (cfg, container) {
            const points = cfg.points
            let path = []
            path.push(['M', points[0].x, points[0].y])
            path.push(['L', points[1].x, points[1].y - sliceNumber])
            path.push(['L', points[2].x, points[2].y - sliceNumber])
            path.push(['L', points[3].x, points[3].y])
            path.push('Z')
            path = this.parsePath(path)
            return container.addShape('path', {
              attrs: {
                fill: cfg.color,
                path
              }
            })
          }
        })

        const chart = new G2.Chart({
          container: this.id,
          forceFit: true,
          padding: [0, 10, 0, 0],
          height: 300
        })
        chart.source(this.data)
        chart.coord('theta', {
          radius: 0.75,
          innerRadius: 0.8
        })
        chart.tooltip({
          showTitle: false,
          itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        })
        var _this = this
        chart.legend({
          position: 'left-center',
          // offsetX: 100,
          itemFormatter(val) {
            let labe = _this.data.find(v => v.item === val).count
            return `${val} ${labe}台` // val 为每个图例项的文本值}else{
          },
        })
        // 辅助文本
        chart.guide().html({
          position: ['50%', '50%'],
          html: '<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">车辆总数<br><span style="color:#8c8c8c;font-size:20px">' + this.count + '</span>台</div>',
          alignX: 'middle',
          alignY: 'middle'
        })
        if (this.data.length > 2) {
          this.color = ['#f5222d', '#2db7f5', '#52c41a']
        } else {
          this.color = ['#52c41a', '#6d6969a6']
        }
        chart.intervalStack()
          .position('percent')
          .color('item', this.color)
          .shape('sliceShape')
          .tooltip('item*percent', (item, percent) => {
            percent = percent * 100 + '%'
            return {
              name: item,
              value: percent
            }
          })
          .style({
            lineWidth: 1,
            stroke: '#fff'
          })
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>