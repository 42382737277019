const args = () => {
  return {
    //API网关提供的appkey
    appkey: undefined,
    //API网关提供的secret
    secret: undefined,
    //API网关IP地址
    ip: undefined,
    //播放模式（决定显示预览还是回放界面）
    playMode: undefined,
    //端口
    port: undefined,
    //抓图存储路径
    snapDir: undefined,
    //紧急录像或录像剪辑存储路径
    videoDir: undefined,
    //布局
    layout: undefined,
    //是否启用HTTPS协议
    enableHTTPS: undefined,
    //加密字段
    encryptedFields: undefined,
    //是否显示工具栏
    showToolbar: undefined,
    //是否显示智能信息
    showSmart: undefined,
    //自定义工具条按钮
    buttonIDs: undefined,
  }
}

export default args()
