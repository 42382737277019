import SERVICE_URLS from '@/api/service.url'

const xunjian = {
  data () {
    return {
      xunjianOverlaysGroup: undefined,
      xunjianPeopleIconPath: 'http://srcp.fangshuoit.com/icon_xunjian_user.png',
      animationTime: 1
    }
  },
  methods: {
    /**
     * 加载巡检人物位置数据
     */
    loadXunjianOverlays () {
      this.loadXunjianData((data) => {
        this.buildXunjianOverlays(data)
      })
    },

    buildXunjianOverlays (data) {
      if (data.body) {
        data.body.forEach((x) => {
          if (x.status === 0 && x.longitude && x.latitude) {
            this.addXunjianOverlayToGroup(
              this.createXunjianPeopleMarker(new AMap.LngLat(x.longitude, x.latitude), x.userId, x.name)
            )
          }
        })
        this.showXunjianOverlaysGroup()
      }
    },

    /**
     * 数据库中加载巡检最新数据
     * @param callback 回调方法
     */
    loadXunjianData (callback) {
      this.$http(this, {
        url: SERVICE_URLS.xj.xjUserRealInfo.searchLocation,
        params: {
          mapInfoId: this.mapId
        },
        noLoadingBar: true,
        noTips: true,
        loading: 'no',
        success: (data) => {
          callback(data)
        },
      })
    },

    /**
     * 添加巡检人员覆盖物至xunjianOverlaysGroup中
     */
    addXunjianOverlayToGroup(overlay) {
      this.getXunjianOverlaysGroup().addOverlay(overlay)
    },

    showXunjianOverlaysGroup() {
      this.getXunjianOverlaysGroup().setMap(this.map)
      this.getXunjianOverlaysGroup().show()
    },

    clearXunjianOverlays() {
      this.getXunjianOverlaysGroup().clearOverlays()
    },

    getXunjianOverlaysGroup() {
      if (!this.xunjianOverlaysGroup) {
        this.xunjianOverlaysGroup = new AMap.OverlayGroup([])
      }
      return this.xunjianOverlaysGroup
    },

    /**
     * 按key查询覆盖物组中的overlay
     * @param key 用户ID
     * @returns {*}
     */
    findXunjianOverlay(key) {
      return this.getXunjianOverlaysGroup()
        .getOverlays()
        .find((x) => x.getExtData()['key'] === key)
    },

    /**
     * 更新地图上巡检人员覆盖物位置
     * 需要判断地图巡检人员是否存在
     * 1、不存在，也在线，新添加覆盖物
     * 2、存在，用户已经离线，移除覆盖物
     * 3、存在的覆盖物需要更新最新经纬度位置
     */
    updateXunjianOverlays() {
      this.loadXunjianData((data) => {
        if (data.body) {
          data.body.forEach((x) => {
            const overlay = this.findXunjianOverlay(x.userId)
            if (x.status === 0 && x.longitude && x.latitude) {
              // 在线
              const newPosition = new AMap.LngLat(x.longitude, x.latitude)
              if (overlay) {
                const m = AMap.GeometryUtil.distance(overlay.getPosition(), newPosition)
                if (m > 0) {
                  overlay.moveTo(newPosition, m / 1000 / (this.animationTime / 3600))
                }
              } else {
                // 创建
                this.getXunjianOverlaysGroup().addOverlay(this.createXunjianPeopleMarker(newPosition, x.userId, x.name))
              }
            } else if (x.status === 1) {
              // 离线
              if (overlay) {
                this.getXunjianOverlaysGroup().removeOverlay(overlay)
              }
            }
          })
        }
      })
    },

    createXunjianPeopleMarker(position, userId, name) {
      const marker = new AMap.Marker({
        position: position,
        icon: new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(33, 33),
          // 图标的取图地址
          image: this.xunjianPeopleIconPath,
          // 图标所用图片大小
          imageSize: new AMap.Size(33, 33),
        }),
        offset: new AMap.Pixel(-18, -33),
        extData: {
          key: userId,
          name: name,
        },
        label: {
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: '<div class="car-marker">' + name + '</div>', //设置文本标注内容
          direction: 'top', //设置文本标注方位
        },
      })
      marker.on('click', (event) => {
        this.mapFitView(event.target)
        const extData = event.target.getExtData()
        this.xunjianItemClickHandle('xunjian', {
          userId: extData['key'],
          name: extData['name'],
        })
      })
      return marker
    },

    /**
     * 巡检人员地图定位事件
     */
    xunjianPeopleFitHandle (userId) {
      this.mapFitView(this.findXunjianOverlay(userId))
    },

    xunjianItemClickHandle (type, item) {
      if (item.status === 0) {
        // 在线才适配地图
        this.mapFitView(this.findXunjianOverlay(item.userId))
      }
      this.rightFormChecked = type
      this.rightFormShow = true
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item)
      })
    }
  }
}

export default xunjian
