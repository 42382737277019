<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="标题" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{ formItem.title }}
      </a-form-item>
      <a-form-item label="指标值/状态" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{ formItem.currentMetricsValue }}
      </a-form-item>
      <a-form-item label="预警值" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{formatCurrentMetricsValue(formItem)}}
      </a-form-item>
      <a-form-item label="采集时间" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{ moment(formItem.collectTime).format('YYYY-MM-DD HH:mm:ss') }}
      </a-form-item>
      <a-form-item label="预警持续时间" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{ formItem.warningLastTime }}
      </a-form-item>
      <a-form-item label="处理人" :label-col="labelCol" :wrapper-col="wrapperCol">
        {{ formItem.dealUserName || '-' }}
      </a-form-item>
      <a-form-item label="处理状态" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group
          v-decorator="[
            'warningDealStatus',
            { initialValue: formItem.warningDealStatus, rules: [{ required: true, message: '请选择处理状态' }] },
          ]"
        >
          <a-radio value="UN_PROCESSED">未处理</a-radio>
          <a-radio value="PROCESSED">已处理</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['remark']" rows="3" placeholder="请输入备注"></a-textarea>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { warningFormVO } from './common/common'
import SERVICE_URLS from '@/api/service.url'
import moment from 'moment'
import common from './common/common'

export default {
  name: 'Form',
  mixins: [common],
  props: {
    type: {
      type: String,
      default: 'edit',
    },
  },
  data() {
    return {
      formItem: warningFormVO(),
      form: this.$form.createForm(this),
      spinShow: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    }
  },
  created() {},
  methods: {
    moment,
    setFields(values) {
      Object.assign(this.formItem, values)
      this.form.setFieldsValue({
        warningDealStatus: this.formItem.warningDealStatus,
        remark: this.formItem.remark,
      })
    },
    loadData(id) {
      this.spinShow = true
      this.$http(this, {
        url: SERVICE_URLS.equipment2.warningApi.view,
        params: {
          id: id,
        },
        noTips: true,
        success: (data) => {
          this.$nextTick(() => {
            this.setFields(data.body)
          })
          this.spinShow = false
        },
      })
    },
    submitEdit() {
      this.spinShow = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            id: this.formItem.id,
            warningDealStatus: values.warningDealStatus,
            remark: values.remark,
          }
          this.$http(this, {
            url: SERVICE_URLS.equipment2.warningApi.deal,
            data: params,
            noTips: true,
            success: (data) => {
              this.$emit('editSuccess', data.body)
              this.spinShow = false
              this.form.resetFields()
            },
            error: (data) => {
              this.$emit('editError')
              this.spinShow = false
              this.form.resetFields()
            },
          })
        }
      })
    },
    cancel() {
      this.sup_this.cancel()
    },
  },
}
</script>

<style scoped></style>
