<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding: 20px;">
      <div class="center">
        <a-radio-group defaultValue="ALL" buttonStyle="solid" @change="onSearch" v-model="carStatus">
          <a-radio-button value="ALL">全部</a-radio-button>
          <a-radio-button value="GOING">执行中</a-radio-button>
          <a-radio-button value="FREE">空闲</a-radio-button>
          <a-radio-button value="OFFLINE">离线</a-radio-button>
        </a-radio-group>
      </div>
      <a-list :bordered="false" :dataSource="searchData">
        <a-list-item
          style="cursor: pointer"
          slot="renderItem"
          @click.native="locateCover(item)"
          class=" default-hover"
          slot-scope="item"
        >
          <a-col :span="2">
            <a-icon type="car"/>
          </a-col>
          <a-col :span="17"> {{ item.platNumber }}
            <a-badge color="#2db7f5" v-if="item.offline === '1'" text="静止"/>
            <a-badge color="green" status="processing" v-if="item.offline === '2'" text="行驶"/>
            <a-badge color="red" v-if="item.offline === '0'" text="离线"/>
            <!--<a-checkable-tag color="green" v-if="item.offline === '1'">静止</a-checkable-tag>
            <a-checkable-tag color="green" v-if="item.offline === '2'">行驶</a-checkable-tag>
            <a-checkable-tag color="red" v-if="item.offline === '0'">离线</a-checkable-tag>-->
          </a-col>
          <a-col :span="5" style="text-align: right;">
            <a-tag v-if="item.status === 'FREE'">空闲</a-tag>
            <a-tag color="green" v-if="item.status === 'GOING'">执行中</a-tag>
          </a-col>
        </a-list-item>
        <div slot="footer">
          <a-pagination
            size="small"
            :current="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="pagination.showTotal"
            @change="onPageChange"
          />
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'SearchCarForm',
    props: {
      searchKey: {
        type: String,
        default: 'car',
      },
      mapId: {
        type: String,
        default: undefined,
      }
    },
    data () {
      return {
        spinShow: false,
        carStatus: 'ALL',
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          fullName: '',
          platNumber: '',
          departmentId: null,
          macId: null,
          mapId: this.mapId,
          offline: undefined,
          status: 'ALL',
        },
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`,
        },
        searchData: [],
      }
    },
    watch: {
      searchKey () {
        this.searchParams.platNumber = this.searchKey
      },
    },
    created () {
      this.onSearch()
    },
    methods: {
      /*搜索方法*/
      onSearch () {
        this.searchParams.status = this.carStatus
        if (this.carStatus === 'ALL') {
          this.searchParams.status = ''
          this.searchParams.offline = ''
        } else if (this.carStatus === 'OFFLINE') {
          this.searchParams.status = ''
          this.searchParams.offline = '0'
        }
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.search,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
          },
          error: () => {},
        })
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.onSearch()
      },
      /*选中定位覆盖物*/
      locateCover (item) {
        this.$emit('onItemClick', 'car', item)
      },
    },
  }
</script>
