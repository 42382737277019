<template>
  <div :id="id"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'bar',
    props: {
      data: Array,
      id: {
        type: String
      }
    },
    data () {
      return {}
    },
    watch: {
      data (val) {
        this.data = val
        this.chart.source(this.data)
        this.chart.repaint()
      }
    },
    mounted () {
      this.initChart()
    },
    methods: {
      initChart () {
        const chart = new G2.Chart({
          container: this.id,
          forceFit: true,
          height: 400,
          padding: [20, 'auto', 40, 'auto']
        })
        chart.source(this.data)
        chart.tooltip({
          showTitle: false,
          itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        })
        chart.axis('duration', {
          label: {
            formatter: val => {
              return val + 'h'
            }
          }
        })
        chart.axis('kilometers', {
          label: {
            formatter: val => {
              return val + 'km'
            }
          }
        })
        chart.legend(false)
        chart.intervalStack()
          .position('item*duration')
          .color('item')
          .tooltip('item*duration', (item, duration) => {
            return {
              name: '时长',
              value: duration + 'h'
            }
          })
        chart.line().position('item*kilometers')
          .tooltip('item*kilometers', (item, kilometers) => {
            return {
              name: '公里',
              value: kilometers + 'km'
            }
          })
        chart.point().position('item*kilometers')
          .tooltip('item*kilometers', (item, kilometers) => {
            return {
              name: '公里',
              value: kilometers + 'km'
            }
          })
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>